import React, {useCallback, useRef, useState} from 'react';
import {Button, Col, Row} from "react-bootstrap";
import {apiEndpoint} from "../../utils/api";
import {useApiFetch} from "../Providers/OidcProvider";
import StandardField from "../Forms/Fields/StandardField";
import {FieldArray, Form, Formik, FormikProps, FormikValues} from "formik";
import {SignificantAdult} from "../Forms/Demographics/SignificantAdultsForm";
import FormControls from "../Forms/Fields/FormControls";
import {NameToValueMap} from "../Forms/FormBuilder";
import * as yup from "yup";
import GeneralErrorModal from "./GeneralErrorModal";
import {CareProviderContact} from "../../types/CareProviderContact";
import {CareProvider} from "../../types/CareProvider";

const careProviderFormSchema = yup.object({
    UUID: yup.string(),
    providerName: yup.string().required(),
    acronym: yup.string(),
    county: yup.string(),
    providerNumber: yup.string(),
    providerType: yup.string(),
    addressStreet: yup.string(),
    addressCity: yup.string(),
    addressState: yup.string(),
    addressZip: yup.string(),
    poBoxStreet: yup.string(),
    poBoxCity: yup.string(),
    poBoxState: yup.string(),
    poBoxZip: yup.string(),
    phone: yup.string(),
    fax: yup.string(),
    email: yup.string(),
    contacts: yup.array().of(
        yup.object().shape({
            UUID: yup.string(),
            lastName: yup.string(),
            firstName: yup.string(),
            phone: yup.string(),
            fax: yup.string(),
            email: yup.string(),
            status: yup.string(),
        })
    )
});

type Props = {
    careProvider: CareProvider | null;
    setCareProvider: (careProvider: CareProvider | null) => void;
    addNewCareProviderWithName: string | false;
    setAddNewCareProviderWithName: (addNewCareProviderWithName: string | false) => void;
    handleCloseParentModal: () => void;
};

const CareProviderForm = (
    {
        careProvider,
        setCareProvider,
        addNewCareProviderWithName,
        setAddNewCareProviderWithName,
        handleCloseParentModal
    }
: Props) => {
    const apiFetch = useApiFetch();
    const formRef = useRef<FormikProps<NameToValueMap>>(null);
    const [formIsModified, setFormIsModified] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const initialValues : NameToValueMap = careProvider ?? {};
    const [showGeneralErrorModal, setShowGeneralErrorModal] = useState<boolean>(false);

    const handleSetFormIsModified = useCallback(async (formIsModified: boolean) => {
        setFormIsModified(formIsModified);
    }, [setFormIsModified]);

    const handleAddCareProviderContact = useCallback(async () => {
        if (careProvider) {
            const endString = careProvider.contacts.length.toString().padStart(12, '0');
            const newCareProvider = {...formRef.current?.values} as CareProvider;

            newCareProvider.contacts = [...formRef.current?.values.contacts];
            newCareProvider.contacts.push({
                UUID: `00000000-0000-0000-0000-${endString}`,
                lastName: '',
                firstName: '',
                phone: '',
                fax: '',
                email: '',
                status: '',
            })

            formRef.current?.setValues(newCareProvider);
            setCareProvider(newCareProvider);
        }
    }, [careProvider, setCareProvider]);

    const handleCareProviderFormSubmit = useCallback(async (values : FormikValues) => {
        if (careProvider) {
            const url = new URL(
                `/v1/providers/save/${careProvider.UUID}`,
                apiEndpoint
            );

            const response = await apiFetch(url.toString(), {
                method: 'PUT',
                body: JSON.stringify({
                    ...values
                })
            });

            if (!response.ok) {
                setShowGeneralErrorModal(true);
                return;
            }

            const data = await response.json();
            formRef.current?.setValues(data);
            setCareProvider(data);
        }
    }, [apiFetch, careProvider, setCareProvider]);

    const handleCloseModal = () => {
        if (addNewCareProviderWithName) {
            setAddNewCareProviderWithName(false);
        }
        setCareProvider(null);
        handleCloseParentModal();
    };

    return <>
        <Formik
            initialValues={initialValues}
            validationSchema={careProviderFormSchema}
            onSubmit={async (values, formikHelpers) => {
                setLoading(true);
                await handleCareProviderFormSubmit(values);
                await handleSetFormIsModified(false);
                setLoading(false);
                handleCloseModal();
            }}
            enableReinitialize={true}
            innerRef={formRef}
        >
            {(props) => {
                const formValues = props.values;

                return <>
                    <Row className="chris-form-bg mx-0 pt-3 pr-2">
                        <Col xs={12} className="pr-0">
                            <h3>Care Providers Directory</h3>
                        </Col>
                    </Row>
                    <Row className="chris-form-bg mx-0 pr-2">
                        <Col xs={12} lg={5} className="pr-0">
                            <StandardField
                                name="providerName"
                                type="text"
                                label="Provider Name"
                                disabled={false}
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                        <Col xs={12} lg={2} className="pr-0">
                            <StandardField
                                name="acronym"
                                type="text"
                                label="Acronym"
                                disabled={false}
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                        <Col xs={12} lg={2} className="pr-0">
                            <StandardField
                                name="county"
                                type="text"
                                label="County"
                                disabled={false}
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                        <Col xs={12} lg={2} className="pr-0">
                            <StandardField
                                name="providerNumber"
                                type="text"
                                label="Provider Number"
                                disabled={true}
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                        <Col xs={12} lg={1} className="pr-0">
                            <StandardField
                                name="providerType"
                                type="select"
                                label="Type"
                                disabled={false}
                                handleSetFormIsModified={handleSetFormIsModified}
                                options={[
                                    {
                                        label: 'A',
                                        value: 'A',
                                        checked: false,
                                    }
                                ]}
                            />
                        </Col>
                    </Row>
                    <Row className="chris-form-bg mx-0 pt-3 pr-2">
                        <Col xs={12} className="pr-0">
                            <h3>Street Address</h3>
                        </Col>
                    </Row>
                    <Row className="chris-form-bg mx-0 pr-2">
                        <Col xs={12} lg={5} className="pr-0">
                            <StandardField
                                name="addressStreet"
                                type="text"
                                label="Street Address"
                                disabled={false}
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                        <Col xs={12} lg={4} className="pr-0">
                            <StandardField
                                name="addressCity"
                                type="text"
                                label="City"
                                disabled={false}
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                        <Col xs={12} lg={1} className="pr-0">
                            <StandardField
                                name="addressState"
                                type="text"
                                label="State"
                                disabled={false}
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                        <Col xs={12} lg={2} className="pr-0">
                            <StandardField
                                name="addressZip"
                                type="text"
                                label="Zip"
                                disabled={false}
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                    </Row>
                    <Row className="chris-form-bg mx-0 pt-3 pr-2">
                        <Col xs={12} className="pr-0">
                            <h3>PO Box Address</h3>
                        </Col>
                    </Row>
                    <Row className="chris-form-bg mx-0 pr-2">
                        <Col xs={12} lg={5} className="pr-0">
                            <StandardField
                                name="poBoxStreet"
                                type="text"
                                label="PO Box Address"
                                disabled={false}
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                        <Col xs={12} lg={4} className="pr-0">
                            <StandardField
                                name="poBoxCity"
                                type="text"
                                label="City"
                                disabled={false}
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                        <Col xs={12} lg={1} className="pr-0">
                            <StandardField
                                name="poBoxState"
                                type="text"
                                label="State"
                                disabled={false}
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                        <Col xs={12} lg={2} className="pr-0">
                            <StandardField
                                name="poBoxZip"
                                type="text"
                                label="Zip"
                                disabled={false}
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                    </Row>
                    <Row className="chris-form-bg mx-0 pt-3 pr-2">
                        <Col xs={12} className="pr-0">
                            <h3>Additional Information</h3>
                        </Col>
                    </Row>
                    <Row className="chris-form-bg mx-0 pr-2">
                        <Col xs={12} lg={3} className="pr-0">
                            <StandardField
                                name="phone"
                                type="text"
                                label="Phone"
                                disabled={false}
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                        <Col xs={12} lg={3} className="pr-0">
                            <StandardField
                                name="fax"
                                type="text"
                                label="Fax"
                                disabled={false}
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                        <Col xs={12} lg={6} className="pr-0">
                            <StandardField
                                name="email"
                                type="text"
                                label="Email"
                                disabled={false}
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                    </Row>
                    <Row className="chris-form-bg mx-0 pt-4 pr-2">
                        <Col
                            xs={12}
                            lg={6}
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-beginning',
                                alignItems: 'flex-end'
                            }}
                        >
                            <h3>Contacts Directory</h3>
                        </Col>
                        <Col
                            xs={12}
                            lg={6}
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Button
                                variant="outline-secondary"
                                type="button"
                                className="d-flex p-1 flex-row align-content-center mr-2"
                                onClick={handleAddCareProviderContact}
                            >
                                Add Contact
                            </Button>
                        </Col>
                    </Row>
                    <FieldArray
                        name="contacts"
                        render={(arrayHelpers: { push: (contact: SignificantAdult | undefined) => void; remove: (row: number) => void; }) => (
                            <Form className="chris-form-bg pt-2">
                                {careProvider && careProvider.contacts?.map((contact: CareProviderContact, index) => {
                                    const foundIndex = formValues.contacts.findIndex((a : CareProviderContact) => {
                                        return a['UUID'] === contact['UUID']
                                    });
                                    const rowIndex = foundIndex ?? index;

                                    return <Row
                                        className="chris-form-bg mx-0 pr-2"
                                        key={`contact-${contact.UUID}-${index}`}
                                    >
                                        <Col xs={12} className='d-lg-none pt-2 pr-0'>
                                            Contact {rowIndex + 1}
                                        </Col>
                                        <Col xs={12} lg={2} className="pr-0">
                                            <StandardField
                                                name='lastName'
                                                type="text"
                                                label="Last Name"
                                                disabled={false}
                                                handleSetFormIsModified={handleSetFormIsModified}
                                                arrayName='contacts'
                                                iteration={rowIndex}
                                            />
                                        </Col>
                                        <Col xs={12} lg={2} className="pr-0">
                                            <StandardField
                                                name='firstName'
                                                type="text"
                                                label="First Name"
                                                disabled={false}
                                                handleSetFormIsModified={handleSetFormIsModified}
                                                arrayName='contacts'
                                                iteration={rowIndex}
                                            />
                                        </Col>
                                        <Col xs={12} lg={2} className="pr-0">
                                            <StandardField
                                                name='phone'
                                                type="text"
                                                label="Phone"
                                                disabled={false}
                                                handleSetFormIsModified={handleSetFormIsModified}
                                                arrayName='contacts'
                                                iteration={rowIndex}
                                            />
                                        </Col>
                                        <Col xs={12} lg={2} className="pr-0">
                                            <StandardField
                                                name='fax'
                                                type="text"
                                                label="Fax"
                                                disabled={false}
                                                handleSetFormIsModified={handleSetFormIsModified}
                                                arrayName='contacts'
                                                iteration={rowIndex}
                                            />
                                        </Col>
                                        <Col xs={12} lg={2} className="pr-0">
                                            <StandardField
                                                name='email'
                                                type="text"
                                                label="Email"
                                                disabled={false}
                                                handleSetFormIsModified={handleSetFormIsModified}
                                                arrayName='contacts'
                                                iteration={rowIndex}
                                            />
                                        </Col>
                                        <Col xs={12} lg={2} className="pr-0">
                                            <StandardField
                                                name="status"
                                                type="select"
                                                label="Status"
                                                disabled={false}
                                                handleSetFormIsModified={handleSetFormIsModified}
                                                arrayName='contacts'
                                                iteration={rowIndex}
                                                options={[
                                                    {
                                                        label: 'A',
                                                        value: 'A',
                                                        checked: false,
                                                    }
                                                ]}
                                            />
                                        </Col>
                                    </Row>
                                })}
                                <Col xs={12} className="pr-0">
                                    <hr/>
                                </Col>
                                <Row className="mx-0 mt-2">
                                    <Col xs={12} className="d-flex flex-row justify-content-between pr-0">
                                        <Button
                                            variant="outline-secondary"
                                            type="button"
                                            className="d-flex pt-2 flex-row align-content-center mr-2"
                                            onClick={async () => {
                                                handleCloseModal();

                                            }}
                                        >Close</Button>

                                        <FormControls
                                            formIsModified={formIsModified}
                                            loading={loading}
                                            submitForm={props.submitForm}
                                            showSave={true}
                                            submitType={'save'}
                                            disableAutoSave={true}
                                            isValid={props.isValid}
                                            saveText={'Save and Close'}
                                        />
                                    </Col>
                                </Row>

                            </Form>
                        )}
                    />
                </>
            }}
        </Formik>
        <GeneralErrorModal
            show={showGeneralErrorModal}
            handleShowGeneralErrorModal={setShowGeneralErrorModal}
        />
    </>;
};

export default CareProviderForm;
