import React from 'react';
import {Col, Row} from "react-bootstrap";
import {List} from "../../../../pages/RecordLocator";
import {BaseTimelineFormValues} from "./TimelineEventForm";
import StandardField from "../../Fields/StandardField";
import {getOptionsForField, yupDateRegex} from "../../../../utils/formHelpers";
import * as yup from "yup";
import moment from "moment";
import {isString} from "formik";

export const END_TIMELINE_STRING = 'End Timeline';
export type END_TIMELINE = typeof END_TIMELINE_STRING;

export type EndTimelineValues = BaseTimelineFormValues & {
    'DemoEntry to Timeline by AN to EndAN::ActionNeeded' : string;
    'DemoEntry to Timeline by AN to EndAN::ANCompletedDate' : string;
    'DemoEntry to Timeline by AN to EndAN::ANFollowUpDate' : string;
    EndDate : string;
    EndNotes : string;
    EndReason : string;
};

const endOfToday = moment().endOf('day').toDate();

export const EndTimelineSchema = {
    'DemoEntry to Timeline by AN to EndAN::ActionNeeded' : yup.string(),
    'DemoEntry to Timeline by AN to EndAN::ANCompletedDate' : yupDateRegex,
    'DemoEntry to Timeline by AN to EndAN::ANFollowUpDate' : yupDateRegex,
    EndDate : yup.date().required().min(
        moment().subtract('2', 'year').toDate(), 'End Timeline Date must be later than ' +
        moment().subtract('2', 'year').format('MM/DD/YYYY')
    ).max(
        endOfToday,
        'Date Must Not Be In The Future.'
    ),
    EndNotes : yup.string(),
    EndReason : yup.string().when('EndDate', {
        is: (EndDate) => EndDate !== '',
        then: yup.string()
            .required('You must enter an Reason when setting End Date')
    })
};

type Props = {
    canSave : boolean;
    siteLists : Array<List>;
    handleSetFormIsModified : (formIsModified : boolean) => Promise<void>;
    handleCheckNegativeDaysElapsed : (eventName : string, date : string) => void;
    negativeDaysElapsed : boolean;
};

const EndTimelineForm = ({
    canSave,
    siteLists,
    handleSetFormIsModified,
    handleCheckNegativeDaysElapsed,
    negativeDaysElapsed
} : Props) => {

    return (
        <>
            <Row className="mx-0 px-2">
                <Col xs={6}>
                    <StandardField
                        name="EndDate"
                        type="date"
                        label="End Timeline Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        onChange={(value) => {
                            handleCheckNegativeDaysElapsed(END_TIMELINE_STRING, isString(value) ? value : '');
                        }}
                        errorNoticeOverride={negativeDaysElapsed
                            ? 'The selected date will cause days elapsed to be negative' : ''
                        }
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12}>
                    <StandardField
                        name="EndReason"
                        type="select"
                        label="Reason"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'InactiveEndTimeline',
                            '',
                            siteLists
                        )}
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12}>
                    <StandardField
                        name="EndNotes"
                        type="textarea"
                        label="Notes"
                        disabled={!canSave}
                        className="textarea-lg"
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
            </Row>
        </>
    );
};

export default EndTimelineForm;