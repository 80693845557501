import React, {useEffect, useState} from 'react';
import ModalBase from './ModalBase';
import {Button, Col, Row} from "react-bootstrap";
import {apiEndpoint} from "../../utils/api";
import {useApiFetch} from "../Providers/OidcProvider";
import CircularLoadingIndicator from "../CircularLoadingIndicator";
import GeneralErrorModal from "./GeneralErrorModal";
import {CareProvider} from "../../types/CareProvider";
import CareProviderForm from "./CareProviderForm";

type Props = {
    selectedCareProviderNumber: string | null;
    setSelectedCareProviderNumber: (selectedCareProvider: string | null) => void;
    addNewCareProviderWithName: string | false;
    setAddNewCareProviderWithName: (addNewCareProviderWithName: string | false) => void;
};

const CareProviderModal = (
    {
        selectedCareProviderNumber,
        setSelectedCareProviderNumber,
        addNewCareProviderWithName,
        setAddNewCareProviderWithName,
    }
: Props) => {
    const apiFetch = useApiFetch();
    const [careProvider, setCareProvider] = useState<CareProvider | null>(null);
    const [showGeneralErrorModal, setShowGeneralErrorModal] = useState<boolean>(false);

    const handleFetchCareProvider = async () => {
        const url = new URL(`/v1/providers/get/${selectedCareProviderNumber}/${selectedCareProviderNumber}`, apiEndpoint);
        const response = await apiFetch(url.toString(), {method: 'GET'});

        if (!response.ok) {
            setShowGeneralErrorModal(true);
            return;
        }

        const data = await response.json();
        setCareProvider(data);
    }

    const handleCloseModal = () => {
        setSelectedCareProviderNumber(null);
        setCareProvider(null);
        setAddNewCareProviderWithName(false);
    };

    useEffect(() => {
        if (selectedCareProviderNumber !== null && careProvider === null) {
            void handleFetchCareProvider();
        }
    });

    useEffect(() => {
        if (addNewCareProviderWithName) {
            setCareProvider({
                UUID: '00000000-0000-0000-0000-000000000000',
                providerName: addNewCareProviderWithName,
                acronym: '',
                county: '',
                providerNumber: '',
                providerType: '',
                addressStreet: '',
                addressCity: '',
                addressState: '',
                addressZip: '',
                poBoxStreet: '',
                poBoxCity: '',
                poBoxState: '',
                poBoxZip: '',
                phone: '',
                fax: '',
                email: '',
                contacts: [],
            })
        }
    }, [addNewCareProviderWithName]);

    return (
        <ModalBase
            show={careProvider !== null}
            title="Care Provider & Contacts"
            size="lg"
            hideConfirmButton={true}
            hideCloseButton={true}
            handleClose={handleCloseModal}
        >
            {!careProvider && <div>
                <Col xs={12}>
                    <CircularLoadingIndicator/>
                </Col>
                <Col xs={12}>
                    <hr/>
                </Col>
                <Row className="mx-0 mt-2">
                    <Col xs={12} className="d-flex flex-row justify-content-start justify-content-lg-end">
                        <Button
                            variant="outline-secondary"
                            type="button"
                            className="d-flex pt-2 flex-row align-content-center mr-2"
                            onClick={async () => {
                                handleCloseModal();
                            }}
                        >Close</Button>
                    </Col>
                </Row>
            </div>}
            {careProvider && <CareProviderForm
                careProvider={careProvider}
                setCareProvider={setCareProvider}
                addNewCareProviderWithName={addNewCareProviderWithName}
                setAddNewCareProviderWithName={setAddNewCareProviderWithName}
                handleCloseParentModal={handleCloseModal}
            />}
            <GeneralErrorModal
                show={showGeneralErrorModal}
                handleShowGeneralErrorModal={setShowGeneralErrorModal}
            />
        </ModalBase>
    );
};

export default CareProviderModal;
