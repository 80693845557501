import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Col, Container, Row} from "react-bootstrap";
import NavHeader from "../components/Navigation/NavHeader";
import ActiveUserNav from "../components/Navigation/ActiveUserNav";
import {ChrisUserProviderContext} from "../components/Providers/ChrisUserProvider";
import {StandardFieldOption} from "../components/Forms/Fields/StandardField";
import Form from "react-bootstrap/Form";
import {apiEndpoint} from "../utils/api";
import {useApiFetch} from "../components/Providers/OidcProvider";
import CircularLoadingIndicator from "../components/CircularLoadingIndicator";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import DataFacilitatorOptionToggle from "../components/DataFacilitator/DataFacilitatorOptionToggle";
import AddDataFacilitatorListOptionForm from "../components/Forms/DataFacilitator/AddDataFacilitatorListOptionForm";
import {FormikValues} from "formik";
import { v4 as uuidv4 } from 'uuid';
import {ActiveChildProviderContext} from "../components/Providers/ActiveChildProvider";
import ActiveChildNav from "../components/Navigation/ActiveChildNav";
import GeneralErrorModal from "../components/Modal/GeneralErrorModal";
import CareProviderModal from "../components/Modal/CareProviderModal";
import BootstrapForm from "react-bootstrap/Form";

export type FacilitatorListOption = {
    UUID: string;
    value: string;
    active: number;
};

export type DisplayFacilitatorListOption = {
    value: string;
    active: React.ReactElement;
};

const mapRawFacilitatorList = (values : any) : FacilitatorListOption => ({
    ...values,
});

const ManageLists = () => {
    const [chrisUserState] = React.useContext(ChrisUserProviderContext);
    const [activeListOptions, setActiveListOptions] = useState<Array<FacilitatorListOption>>([]);
    const [displayActiveListOptions, setDisplayActiveListOptions] = useState<Array<DisplayFacilitatorListOption>>([]);
    const [activeList, setActiveList] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [activeChild, loadActiveChild] = React.useContext(ActiveChildProviderContext);
    const [showGeneralErrorModal, setShowGeneralErrorModal] = useState<boolean>(false);
    const [selectedCareProviderNumber, setSelectedCareProviderNumber] = useState<string | null>(null);
    const [addNewCareProviderWithName, setAddNewCareProviderWithName] = useState<string | false>(false);
    const [optionFilter, setOptionFilter] = useState('');

    const apiFetch  = useApiFetch();

    const lists : Array<StandardFieldOption> = [
        {label: 'Cities List', value: 'Cities List', checked: false},
        {label: 'Code List', value: 'Code List', checked: false},
        {label: 'Contact Log List', value: 'Contact Log List', checked: false},
        {label: 'HomeZoneSchool List', value: 'HomeZoneSchool List', checked: false},
        {label: 'Language List', value: 'Language List', checked: false},
        {label: 'Location List', value: 'Location List', checked: false},
        {label: 'On Hold List', value: 'On Hold List', checked: false},
        {label: 'Sub Location List', value: 'Sub Location List', checked: false},
        {label: 'AppointmentTypeList', value: 'AppointmentTypeList', checked: false},
        {label: 'Care Providers Directory', value: 'Provider List', checked: false}
    ];

    const columns = useMemo(() => {
        return [
            {
                dataField: 'UUID',
                hidden: true,
                sort: false,
                text: 'UUID'
            },
            {
                dataField: 'value',
                text: 'Value',
                sort: true,
                formatter: (cell: string, row: FacilitatorListOption, rowIndex: number, extraData: any) => {
                    return <div>
                        {activeList !== 'Provider List' && row.value}
                        {activeList === 'Provider List' && <a
                            href='#'
                            onClick={() => {
                                setSelectedCareProviderNumber(row.UUID);
                            }}
                        >
                            {row.value}
                        </a>}
                    </div>
                }
            },
            {
                dataField: 'active',
                text: 'Active',
                sort: false
            }
        ]
    }, [activeList]);

    const handleUpdateListOption = useCallback(async (
        value: string, active: number, activeListOptions: Array<FacilitatorListOption>, activeList: string
    ) => {
        const url = new URL(
            `/v1/lists/data-facilitator/${activeList}/${value}/${active}`,
            apiEndpoint
        );
        const response = await apiFetch(url.toString(), {
            method: 'PUT'
        });

        if (!response.ok) {
            setShowGeneralErrorModal(true);
            return;
        }

        const optionIndex = activeListOptions.findIndex(
            (listOption) => listOption.value === value
        );
        activeListOptions[optionIndex].active = active;
        setActiveListOptions(activeListOptions);
    }, [apiFetch, setActiveListOptions]);

    const handleSetActiveList = useCallback(async (list : string) => {
        setActiveList(list);
        setLoading(true);

        const url = new URL(`/v1/lists/data-facilitator/${list}`, apiEndpoint);
        const response = await apiFetch(url.toString());

        if (!response.ok) {
            setShowGeneralErrorModal(true);
            return;
        }

        const data = await response.json();

        const activeListOptions = data.map(mapRawFacilitatorList);
        setActiveListOptions(activeListOptions);
        setDisplayActiveListOptions(activeListOptions.map((activeListOption : FacilitatorListOption) => ({
                ...activeListOption,
                active: <DataFacilitatorOptionToggle
                    activeListOption={activeListOption}
                    handleUpdateListOption={handleUpdateListOption}
                    activeListOptions={activeListOptions}
                    activeList={list}
                />
            }
        )));
        setLoading(false);
    }, [apiFetch, setLoading, setActiveListOptions, setActiveList, handleUpdateListOption]);

    const handleAddNewListOption = useCallback(async (values : FormikValues) => {
        const url = new URL(
            `/v1/lists/data-facilitator/${activeList}/${values.optionValue}/1`,
            apiEndpoint
        );
        const response = await apiFetch(url.toString(), {
            method: 'PUT'
        });

        if (!response.ok) {
            setShowGeneralErrorModal(true);
            return;
        }

        activeListOptions.push({value: values.optionValue, active: 1, UUID: uuidv4()});
        setActiveListOptions(activeListOptions);
        setDisplayActiveListOptions(activeListOptions.map((activeListOption : FacilitatorListOption) => ({
                ...activeListOption,
                active: <DataFacilitatorOptionToggle
                    activeListOption={activeListOption}
                    handleUpdateListOption={handleUpdateListOption}
                    activeListOptions={activeListOptions}
                    activeList={activeList}
                />
            }
        )));
    }, [apiFetch, activeListOptions, handleUpdateListOption, activeList]);

    const handleAddNewCareProviderListOption = (values : FormikValues) => {
        setAddNewCareProviderWithName(values.optionValue);
    }

    useEffect(() => {
        void loadActiveChild();
    }, [loadActiveChild]);

    return (
        <>
            <NavHeader />
            <ActiveUserNav chrisUser={chrisUserState.chrisUser}>
                {activeChild && activeChild.activeChild ? (
                    <ActiveChildNav
                        activeChild={activeChild.activeChild}
                    />
                ) : (<></>)}
            </ActiveUserNav>
            <Container>
                {chrisUserState.loading ? (
                    <CircularLoadingIndicator/>
                ) : (
                    <Row>
                        <Col xs={4} className="d-flex flex-column align-items-start mt-4">
                            <label>Available Lists</label>
                            <Form.Control
                                type="select"
                                as="select"
                                name="providerLists"
                                value={activeList}
                                onChange={async (e) => {
                                    await handleSetActiveList(e.currentTarget.value);
                                    setOptionFilter('');
                                }}
                                disabled={false}
                            >
                                <option value=""> -- Select --</option>
                                {lists.map((option : StandardFieldOption, index) => {
                                    return <option
                                        value={option.value}
                                        key={index}
                                    >{option.label}</option>;
                                })}
                            </Form.Control>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col xs={12} className="d-flex flex-column align-items-start mt-2">
                        {loading && (
                            <CircularLoadingIndicator/>
                        )}
                        {!loading && activeListOptions && activeListOptions.length > 0 && displayActiveListOptions && (
                            <>
                                <AddDataFacilitatorListOptionForm
                                    handleSubmit={activeList === 'Provider List' ? handleAddNewCareProviderListOption : handleAddNewListOption}
                                />
                                {activeList === 'Provider List' && <Row className="mx-0 my-2 w-100">
                                    <Col xs={12} md={6} className="pl-0">
                                        <BootstrapForm.Label column={false}>Option Filter</BootstrapForm.Label>
                                        <BootstrapForm.Control
                                            type='text'
                                            name='optionFilter'
                                            value={optionFilter}
                                            onChange={async (e) => {
                                                setOptionFilter(e.target.value)
                                            }}
                                        />
                                    </Col>
                                </Row>}
                                <BootstrapTable
                                    bootstrap4
                                    keyField='UUID'
                                    data={displayActiveListOptions.filter((option : DisplayFacilitatorListOption) => option.value.toLowerCase().includes(optionFilter.toLowerCase()))}
                                    columns={columns}
                                    pagination={ paginationFactory({sizePerPage: 50}) }
                                    striped
                                    defaultSorted={[
                                        {
                                            dataField: "value",
                                            order: "asc"
                                        }
                                    ]}
                                />
                            </>
                        )}
                    </Col>
                </Row>
                <GeneralErrorModal
                    show={showGeneralErrorModal}
                    handleShowGeneralErrorModal={setShowGeneralErrorModal}
                />
                <CareProviderModal
                    selectedCareProviderNumber={selectedCareProviderNumber}
                    setSelectedCareProviderNumber={setSelectedCareProviderNumber}
                    addNewCareProviderWithName={addNewCareProviderWithName}
                    setAddNewCareProviderWithName={setAddNewCareProviderWithName}
                />
            </Container>
        </>
    );
};

export default ManageLists;
