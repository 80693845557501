import React from 'react';
import {Col, Row} from "react-bootstrap";
import {List} from "../../../../pages/RecordLocator";
import {BaseTimelineFormValues, getDaysElapsedError, requiredFieldMsg, SudoRequiredField} from "./TimelineEventForm";
import * as yup from 'yup';
import {getOptionsForField, yupDateRegex} from "../../../../utils/formHelpers";
import StandardField from "../../Fields/StandardField";
import moment from "moment";
import {isString} from "formik";

export const IEP_IFSP_STRING = 'IEP/IFSP';
export type IEP_IFSP = typeof IEP_IFSP_STRING;

export type IEPIFSPValues = BaseTimelineFormValues & {
    'DemoEntry to Timeline by AN to IEPIFSPAN::ActionNeeded' : string;
    'DemoEntry to Timeline by AN to IEPIFSPAN::ANCompletedDate' : string;
    'DemoEntry to Timeline by AN to IEPIFSPAN::ANFollowUpDate' : string;
    IEPIFSPDate : string;
    IEPIFSPEducationalEnvironment1 : string;
    IEPIFSPEducationalEnvironment2 : string;
    IEPIFSPNotes : string;
    IEPIFSPParentConsentDate : string;
    IEPIFSPServiceInitiatedDate1 : string;
    IEPIFSPServiceInitiatedDate2 : string;
    IEPIFSPServiceLocation1 : string;
    IEPIFSPServiceLocation2 : string;
    IEPIFSPServiceType1 : string;
    IEPIFSPServiceType2 : string;
};

const endOfToday = moment().endOf('day').toDate();

export const IEPIFSPSchema = {
    'DemoEntry to Timeline by AN to IEPIFSPAN::ActionNeeded' : yup.string(),
    'DemoEntry to Timeline by AN to IEPIFSPAN::ANCompletedDate' : yupDateRegex,
    'DemoEntry to Timeline by AN to IEPIFSPAN::ANFollowUpDate' : yupDateRegex,
    IEPIFSPDate : yup.date().min(
        moment().subtract('2', 'year').toDate(), 'IEP/IFSP Date must be later than ' +
        moment().subtract('2', 'year').format('MM/DD/YYYY')
    ).max(
        endOfToday,
        'Date Must Not Be In The Future.'
    ),
    IEPIFSPEducationalEnvironment1 : yup.string(),
    IEPIFSPEducationalEnvironment2 : yup.string(),
    IEPIFSPNotes : yup.string(),
    IEPIFSPParentConsentDate : yup.date().min(
        moment().subtract('2', 'year').toDate(), 'Parent Consent for Placement Date must be later than ' +
        moment().subtract('2', 'year').format('MM/DD/YYYY')
    ).max(
        endOfToday,
        'Date Must Not Be In The Future.'
    ),
    IEPIFSPServiceInitiatedDate1 : yupDateRegex,
    IEPIFSPServiceInitiatedDate2 : yupDateRegex,
    IEPIFSPServiceLocation1 : yup.string(),
    IEPIFSPServiceLocation2 : yup.string(),
    IEPIFSPServiceType1 : yup.string(),
    IEPIFSPServiceType2 : yup.string()
};

type Props = {
    canSave : boolean;
    siteLists : Array<List>;
    handleSetFormIsModified : (formIsModified : boolean) => Promise<void>;
    handleCheckNegativeDaysElapsed : (eventName : string, date : string) => void;
    negativeDaysElapsed : boolean;
    sudoRequiredFields: Array<SudoRequiredField>;
};

const IEPIFSPForm = ({
    canSave,
    siteLists,
    handleSetFormIsModified,
    handleCheckNegativeDaysElapsed,
    negativeDaysElapsed,
    sudoRequiredFields
} : Props) => {

    return (
        <>
            <Row className="mx-0 px-2">
                <Col xs={12} lg={4}>
                    <StandardField
                        name="IEPIFSPDate"
                        type="date"
                        label="IEP/IFSP Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        onChange={(value) => {
                            handleCheckNegativeDaysElapsed(IEP_IFSP_STRING, isString(value) ? value : '');
                        }}
                        errorNoticeOverride={getDaysElapsedError(
                            'IEPIFSPDate',
                            negativeDaysElapsed,
                            sudoRequiredFields
                        )}
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <StandardField
                        name="IEPIFSPParentConsentDate"
                        type="date"
                        label="Parent Consent for Placement Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        errorNoticeOverride={sudoRequiredFields.find(
                            (sudoRequiredField) =>
                                sudoRequiredField.name === 'IEPIFSPParentConsentDate'
                        )?.showError ? requiredFieldMsg['IEPIFSPParentConsentDate'] : ''}
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2 condensed-form-row">
                <Col xs={12} lg={3} className="flex-row justify-content-center d-none d-lg-flex">
                    <strong>Service Initiation Date</strong>
                </Col>
                <Col xs={12} lg={3} className="flex-row justify-content-center d-none d-lg-flex">
                    <strong>Educational Env.</strong>
                </Col>
                <Col xs={12} lg={3} className="flex-row justify-content-center d-none d-lg-flex">
                    <strong>Service Location</strong>
                </Col>
                <Col xs={12} lg={3} className="flex-row justify-content-center d-none d-lg-flex">
                    <strong>Service Type</strong>
                </Col>

                <Col xs={12} lg={3}>
                    <StandardField
                        name="IEPIFSPServiceInitiatedDate1"
                        type="date"
                        label="Service Initiation Date 1:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="IEPIFSPEducationalEnvironment1"
                        type="select"
                        label="Educational Environment 1:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                        options={getOptionsForField(
                            'AgencyList',
                            '',
                            siteLists
                        )}
                        hideCheckboxDescription={true}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="IEPIFSPServiceLocation1"
                        type="select"
                        label="Service Location 1:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                        options={getOptionsForField(
                            'ProviderList',
                            '',
                            siteLists
                        )}
                        hideCheckboxDescription={true}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="IEPIFSPServiceType1"
                        type="select"
                        label="Service Type 1:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                        options={getOptionsForField(
                            'ServiceType',
                            '',
                            siteLists
                        )}
                        hideCheckboxDescription={true}
                    />
                </Col>
                <Col xs={12}>
                    <hr className="mt-0 mb-1" />
                </Col>

                <Col xs={12} lg={3}>
                    <StandardField
                        name="IEPIFSPServiceInitiatedDate2"
                        type="date"
                        label="Service Initiation Date 2:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="IEPIFSPEducationalEnvironment2"
                        type="select"
                        label="Educational Environment 2:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                        options={getOptionsForField(
                            'AgencyList',
                            '',
                            siteLists
                        )}
                        hideCheckboxDescription={true}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="IEPIFSPServiceLocation2"
                        type="select"
                        label="Service Location 2:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                        options={getOptionsForField(
                            'ProviderList',
                            '',
                            siteLists
                        )}
                        hideCheckboxDescription={true}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="IEPIFSPServiceType2"
                        type="select"
                        label="Service Type 2:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                        options={getOptionsForField(
                            'ServiceType',
                            '',
                            siteLists
                        )}
                        hideCheckboxDescription={true}
                    />
                </Col>
                <Col xs={12}>
                    <hr className="mt-0 mb-1" />
                </Col>

            </Row>
        </>
    );
};

export default IEPIFSPForm;